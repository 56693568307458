import * as React from 'react';

import { Props } from './icon-props';

export function SlackLogo(props: Props) {
  return (
    <svg {...props} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g transform="matrix(4.1608 0 0 4.1608 -4032.6 -2712.9)">
          <g fill="#E01E5A">
            <path d="m994.98 729.63c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9 5.8-12.9 12.9-12.9h12.9v12.9z" />
            <path d="m1001.5 729.63c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9v-32.3z" />
          </g>
          <g fill="#36C5F0">
            <path d="m1014.4 677.83c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9 12.9 5.8 12.9 12.9v12.9h-12.9z" />
            <path d="m1014.4 684.33c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9h-32.3c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3z" />
          </g>
          <g fill="#2EB67D">
            <path d="m1066.2 697.23c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9h-12.9v-12.9z" />
            <path d="m1059.7 697.23c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9v-32.3c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3z" />
          </g>
          <g fill="#ECB22E">
            <path d="m1046.8 749.03c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9v-12.9h12.9z" />
            <path d="m1046.8 742.53c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9h-32.3z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
