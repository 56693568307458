import * as React from 'react';

import { Props } from './icon-props';

export function CFALogo(props: Props) {
  return (
    <svg {...props} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <g stroke="null">
        <path
          d="m455.58 121.65l-0.085892-1.3657c-0.014724-4.2468-3.475-7.7217-7.7119-7.7462-35.091-1.3276-123.16-10.449-186.51-66.782l-1.2749-0.69941 0.038038-0.23436c-1.2172-0.74235-2.6222-1.1694-4.0786-1.1792-1.8602 0.03313-3.7056 0.74235-5.1388 2.0271-63.446 56.419-151.52 65.541-186.45 66.868-4.3903 0.024541-7.8505 3.4995-7.8702 7.7462l-0.080984 2.0136c5.8002 158.93 104.48 282.22 195.21 344.74 2.6173 1.778 6.1204 1.778 8.7144 0l1.227-0.82456c90.511-62.882 188.49-186.04 194.01-344.57z"
          fill="#424953"
        />
        <path
          d="m447.78 112.54c-35.091-1.3276-123.16-10.449-186.51-66.782l-1.2749-0.69941 0.038038-0.23436c-1.2074-0.74235-2.5976-1.1595-4.0406-1.1792v424.73c1.5338 0 3.0529-0.45032 4.3474-1.3375l1.227-0.82456c90.513-62.88 188.49-186.04 194.01-344.57l-0.085892-1.3657c-0.013497-4.2455-3.475-7.7205-7.7119-7.745z"
          fill="#303842"
        />
        <polygon
          points="350.22 215.05 315.83 180.66 233.91 262.59 196.17 224.85 161.78 259.23 233.91 331.36 233.91 331.36 233.91 331.36"
          fill="#FECD57"
        />
      </g>
    </svg>
  );
}
