import * as React from 'react';

import { Props } from './icon-props';

export function Rocket(props: Props) {
  return (
    <svg
      {...props}
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m32.781 479.22c26.27-48.957 57.457-104.43 66.984-113.95 6.27-6.273 14.609-9.727 23.48-9.727 8.875 0 17.211 3.453 23.484 9.727 12.949 12.949 12.949 34.02 0 46.969-9.53 9.531-64.995 40.71-113.95 66.984z"
        fill="#FECD57"
      />
      <path
        d="m156.39 355.6c-9.148-9.152-21.145-13.727-33.141-13.727-11.992 0-23.984 4.574-33.141 13.727-18.3 18.309-90.105 156.4-90.105 156.4s138.09-71.809 156.39-90.105c18.304-18.309 18.3-47.981-4e-3 -66.29zm-19.274 46.934c-1.66 1.555-12.441 10.469-70.109 42.453 31.984-57.668 40.898-68.453 42.461-70.105 3.684-3.672 8.578-5.688 13.781-5.688 5.223 0 10.133 2.035 13.828 5.727 7.61 7.609 7.621 19.98 0.039 27.613z"
        fill="#F5BA45"
      />
      <path
        d="m168.6 399.54s-13.898-10.348-29.551-26c-15.656-15.66-27.18-30.727-27.18-30.727l7.68-28.945 78.586 78.586-29.535 7.086z"
        fill="#303842"
      />
      <path
        d="m192.9 398.91s-19.75-14.715-42.008-36.973c-22.254-22.254-38.637-43.672-38.637-43.672l10.918-41.148 111.72 111.71-41.988 10.082z"
        fill="#424953"
      />
      <g fill="#D94452">
        <path d="m364.38 360.26l-7.965-63.844-121.53 92.406-7.73 104.36c56.46-27.902 137.22-132.92 137.22-132.92z" />
        <path d="m151.73 147.61l63.844 7.967-92.406 121.52-104.36 7.735c27.91-56.461 132.92-137.23 132.92-137.23z" />
      </g>
      <path
        d="m512 0c-108.59 19.766-212.48 71.621-296.43 155.57-37.059 37.051-67.852 78.008-92.406 121.52 0 0 32.746 39.18 51.918 58.641 19.172 19.453 59.82 53.094 59.82 53.094 43.52-24.547 84.469-55.344 121.52-92.406 83.958-83.946 135.82-187.84 155.58-296.43z"
        fill="#E5E8EC"
      />
      <path
        d="m243.54 287.77c-45.012 45.012-120.56 101.24-120.56 101.24s56.227-75.547 101.24-120.56c45.012-45.014 57.828-46.395 61.77-42.453 3.945 3.942 2.559 16.763-42.453 61.774z"
        fill="#EC5564"
      />
      <path
        d="m316.7 195.31c-19.559-19.561-19.559-51.381 0-70.939 19.555-19.555 51.375-19.555 70.934 6e-3 19.559 19.553 19.559 51.373 0 70.934-19.559 19.551-51.379 19.551-70.934-1e-3z"
        fill="#303842"
      />
      <path
        d="m393.09 118.91c-22.602-22.607-59.254-22.607-81.855-8e-3 -22.602 22.605-22.602 59.254 0 81.859 22.602 22.602 59.254 22.602 81.855 0 22.601-22.605 22.601-59.253 0-81.851zm-10.918 70.934c-16.547 16.545-43.473 16.545-60.02 0-16.551-16.547-16.551-43.475 0-60.021 16.547-16.547 43.469-16.547 60.02 0 16.547 16.546 16.547 43.474 0 60.021z"
        fill="#A9B1BC"
      />
      <path
        d="M432,80c14.047,14.047,29.473,25.873,45.832,35.492C493.434,78.094,504.84,39.359,512,0   c-39.359,7.16-78.094,18.566-115.492,34.16C406.125,50.527,417.953,65.953,432,80z"
        fill="#D94452"
      />
    </svg>
  );
}
